import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { PortalModal } from "clutch/src/PortalModal/PortalModal";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import {
  GRENADE_TYPES,
  LINEUP_DIFFICULTIES,
  LINEUP_SIDES,
  LINEUPS_MAPS,
  MAPS,
} from "@/game-cs2-marketing/constants.mjs";
import { getGrenadeIcon, getMapImage } from "@/game-cs2-marketing/utils.mjs";
import { ModalCSS } from "@/game-csgo/GrenadeLineups.style";
import GrenadeLineupCards, {
  getLineupTags,
} from "@/game-csgo/GrenadeLineupsCards.jsx";
import GrenadeLineupsMap, {
  useLineupGroup,
} from "@/game-csgo/GrenadeLineupsMap.jsx";
import CopyLinkIcon from "@/inline-assets/copy-link.svg";
import ShareIcon from "@/inline-assets/share.svg";
import FilterBar from "@/shared/FilterBar.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import VideoFrame from "@/shared/VideoFrame.jsx";
import LineupModal from "@/shared-fps/LineupModal.jsx";
import globals from "@/util/global-whitelist.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import shareClickHandler from "@/util/share-click-handler.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const GRENADE_POSITION = "end";
const SORT_OPTIONS = {
  date: {
    value: "date",
    text: ["cs2:lineups.mostRecent", "Most Recent"] as Translation,
  },
  difficulty: {
    value: "difficulty",
    text: ["cs2:lineups.difficulty", "Difficulty"] as Translation,
  },
};

const FiltersCSS = () => css`
  .filter-controllers {
    width: 100%;
  }
`;

function Filters({
  side,
  setSide,
  grenadeType,
  setGrenadeType,
  selectedMap,
  setSelectedMap,
  difficulty,
  setDifficulty,
  selectedSort,
  setSort,
}) {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const mapOptions = [
      {
        value: "all",
        text: ["cs2:maps.allMaps", "All Maps"] as Translation,
      },
    ];
    mapOptions.push(
      ...LINEUPS_MAPS.map((id) => {
        const m = MAPS[id];
        return {
          value: id,
          text: [m.i18nKey, m.name] satisfies Translation,
          image: getMapImage(id),
        };
      }),
    );
    return mapOptions;
  }, []);
  const grenadeOptions = useMemo(() => {
    return Object.values(GRENADE_TYPES).map((d) => {
      const GrenadeIcon = getGrenadeIcon(d.key);
      return {
        value: d.key,
        text: d.t,
        icon: d.key !== GRENADE_TYPES.all.key ? <GrenadeIcon /> : null,
      };
    });
  }, []);
  return (
    <FilterBar className={FiltersCSS()}>
      <Select
        containerClassName="select-container"
        onChange={(value) => setSelectedMap(value)}
        selected={selectedMap}
        options={options}
      />
      <ButtonGroup className="button-filters">
        {Object.values(LINEUP_SIDES).map((s) => (
          <Button
            key={s.key}
            active={side === s.key}
            onClick={() => setSide(s.key)}
          >
            {t(...s.t)}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup className="difficulty-filters">
        {Object.values(LINEUP_DIFFICULTIES).map((d) => (
          <Button
            key={d.key}
            iconLeft={<span className="difficulty" data-difficulty={d.key} />}
            active={difficulty === d.key}
            onClick={() => setDifficulty(d.key)}
          >
            {t(...d.t)}
          </Button>
        ))}
      </ButtonGroup>
      <Select
        containerClassName="select-container"
        onChange={(value) => setGrenadeType(value)}
        selected={grenadeType}
        options={grenadeOptions}
      />
      <div className="flex align-center gap-2 flex-100 justify-end">
        <span className="type-caption shade1">
          {t("common:sortBy", "Sort:")}
        </span>
        <Select
          options={Object.values(SORT_OPTIONS)}
          selected={selectedSort}
          onChange={setSort}
        />
      </div>
    </FilterBar>
  );
}

function UserActions({ selectedLineupId, setpos }) {
  const { t } = useTranslation();
  const onClickShare = useCallback(() => {
    sendInteractionEvent("cs2-lineups-share", {
      lineupId: selectedLineupId,
    });
    shareClickHandler(t);
  }, [t, selectedLineupId]);

  const onClickSetpos = useCallback(() => {
    globals.navigator.clipboard.writeText(setpos);
    sendInteractionEvent("cs2-lineups-setpos", {
      lineupId: selectedLineupId,
    });
  }, [selectedLineupId, setpos]);

  return (
    <div className="flex row gap-1">
      <Button
        className="type-form--button flex-button setpos"
        iconLeft={<CopyLinkIcon width="18px" height="18px" />}
        emphasis="high"
        onClick={onClickSetpos}
        data-tooltip={t("cs2:setpos.copied", "Setpos copied to clipboard!")}
        data-event="click"
      >
        <span className="text">
          {t("cs2:lineups.copySetPos", "Copy Setpos")}
        </span>
      </Button>
      <Button
        className="type-form--button flex-button"
        iconLeft={<ShareIcon width="18px" height="18px" />}
        onClick={onClickShare}
        bgColor="var(--shade5)"
        bgColorHover="var(--shade5-50)"
        data-event="click"
        data-tooltip={t("common:share.copied", "Web link copied to clipboard!")}
      >
        {t("common:share.share", "Share")}
      </Button>
    </div>
  );
}

function GrenadeLineups() {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const {
    cs2: { lineups: allLineups },
  } = useSnapshot(readState);
  const modalRef = useRef(null);
  const modalInnerRef = useRef(null);

  const [selectedMap, setSelectedMap] = useQuery<string>("map", "all");
  const [selectedLineupId, setSelectedLineupId] = useQuery<string>("lineup");
  const [selectedLineupMap, setSelectedLineupMap] = useQuery<string>(
    "lineupMap",
    null,
  );
  const [side, setSide] = useQuery<string>("side", LINEUP_SIDES.both.key);
  const [grenadeType, setGrenadeType] = useQuery<string>(
    "grenade",
    GRENADE_TYPES.all.key,
  );
  const [difficulty, setDifficulty] = useQuery<string>(
    "difficulty",
    LINEUP_DIFFICULTIES.all.key,
  );
  const [selectedSort, setSort] = useQuery<string>(
    "sort",
    SORT_OPTIONS.date.value,
  );

  const lineups = useMemo(() => {
    return selectedMap === "all"
      ? Object.values(allLineups).flat(1)
      : !(allLineups[selectedMap] instanceof Error) &&
          allLineups[selectedMap]?.length
        ? allLineups[selectedMap]
        : [];
  }, [allLineups, selectedMap]);
  const filteredLineups = useMemo(() => {
    return lineups
      .filter(
        (l) =>
          (grenadeType !== GRENADE_TYPES.all.key
            ? grenadeType === l.grenade
            : true) &&
          (side !== LINEUP_SIDES.both.key ? l.side === side : true) &&
          (difficulty !== LINEUP_DIFFICULTIES.all.key
            ? l.difficulty === difficulty
            : true),
      )
      .sort((a, b) => {
        if (selectedSort === SORT_OPTIONS.difficulty.value) {
          return (
            LINEUP_DIFFICULTIES[a.difficulty.toLowerCase()].scale -
            LINEUP_DIFFICULTIES[b.difficulty.toLowerCase()].scale
          );
        }
        return 0;
      });
  }, [lineups, selectedSort, difficulty, grenadeType, side]);
  const mapLineups = lineups.filter((l) => l.map === selectedLineupMap);
  const selectedLineup = lineups.find(
    (l) => l.id === selectedLineupId && selectedLineupMap === l.map,
  );
  const lineupsRef = useRef(null);

  const onModalClose = useCallback(() => {
    setSelectedLineupId(null);
    setSelectedLineupMap(null);
  }, [setSelectedLineupId, setSelectedLineupMap]);

  const onContainerClick = useCallback((e) => {
    if (e.target === modalInnerRef.current) {
      modalRef?.current?.close?.();
    }
  }, []);

  const setSelectedLineup = useCallback(
    (lineup) => {
      if (!lineup) return;
      setSelectedLineupId(lineup.id);
      setSelectedLineupMap(lineup.map);
    },
    [setSelectedLineupId, setSelectedLineupMap],
  );

  useEffect(() => {
    if (!modalRef?.current) return;
    if (selectedLineupId) {
      if (modalRef?.current?.open) return;
      modalRef?.current?.showModal?.();
    } else {
      modalRef?.current?.close?.();
    }
  }, [selectedLineupId]);

  useEffect(() => {
    if (selectedLineupId && lineupsRef.current) {
      for (const lineup of lineupsRef.current.childNodes.values()) {
        if (lineup.id === selectedLineupId) {
          lineup.scrollIntoView({ behaviour: "smooth" });
        }
      }
    }
  }, [selectedLineupId]);
  const relatedLineups = useLineupGroup(
    selectedLineup,
    mapLineups,
    GRENADE_POSITION,
  );

  const lineupListProps = {
    side,
    setSide,
    selectedMap,
    setSelectedMap,
    grenadeType,
    setGrenadeType,
    difficulty,
    setDifficulty,
    selectedSort,
    setSort,
  };

  return (
    <PageContainer>
      <PortalModal onModalClose={onModalClose} forwardedRef={modalRef}>
        <div
          className="flex column gap-3"
          onClick={onContainerClick}
          ref={modalInnerRef}
        >
          {selectedLineup ? (
            <LineupModal
              title={selectedLineup.title}
              description={selectedLineup.description}
              tags={getLineupTags(t, selectedLineup)}
              VideoComponent={
                <VideoFrame
                  src={{
                    mp4: `${appURLs.CDN_VIDEOS}/cs2/lineups/${selectedLineup.map}/${selectedLineup.video.mp4}`,
                  }}
                  className="video-frame"
                  startTime={undefined}
                  footer={undefined}
                  videoRef={videoRef}
                  isMuted
                  onVolumeChange={undefined}
                />
              }
              MapComponent={
                <GrenadeLineupsMap
                  lineups={mapLineups}
                  setSelectedLineup={setSelectedLineup}
                  selectedLineup={selectedLineup}
                  selectedMap={selectedLineup.map}
                  grenadePosition={GRENADE_POSITION}
                />
              }
              className={ModalCSS()}
              UserActions={
                <UserActions
                  setpos={selectedLineup.setpos}
                  selectedLineupId={selectedLineup.id}
                />
              }
            />
          ) : null}
          {relatedLineups.length > 1 ? (
            <GrenadeLineupCards lineups={relatedLineups} compact />
          ) : null}
        </div>
      </PortalModal>
      <PageHeader
        title={t("cs2:meta.lineups.title", "Lineups")}
        className="header"
      />
      <Filters {...lineupListProps} />
      <GrenadeLineupCards lineups={filteredLineups} compact={undefined} />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["cs2:meta.lineups.title", "CS2 Lineups"],
    description: [
      "cs2:meta.lineups.description",
      "Explore the best tactical lineups on all maps for CS2",
    ],
    subtitle: [
      `cs2:lineups.subtitle`,
      "Check out the best Grenade, Smoke, and Molly lineups for maps in CS2!",
    ],
  };
}

export default GrenadeLineups;
