import { css } from "goober";

export const ModalCSS = () => css`
  .video-frame {
    video {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .map-container {
    position: relative;
    height: 100%;

    .react-transform-wrapper {
      height: 100%;
      width: 100%;
    }

    .react-transform-component {
      margin: 0 auto;
    }
  }
`;
