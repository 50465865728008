import React, { useMemo, useState } from "react";
import { styled } from "goober";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";

import {
  getGrenadeIcon,
  getMapLayoutOffense,
} from "@/game-cs2-marketing/utils.mjs";

export const MAP_SIZE = 708;

const Map = styled("div")`
  cursor: grab;
  position: relative;
  width: auto;
  height: 100%;
  img {
    width: ${MAP_SIZE}px;
    height: ${MAP_SIZE}px;
    object-fit: contain;
  }
  svg.lineups {
    position: absolute;
    top: 0;
    left: 0;
  }
  .grenade-svg {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
    transform-origin: center;
    &,
    svg {
      width: var(--sp-10);
      height: var(--sp-10);
    }

    &.selected {
      background: var(--shade0-15);
      border-radius: 50%;
      &,
      svg {
        width: var(--sp-12);
        height: var(--sp-12);
      }
    }
    &.not-selected {
      display: none;
    }

    .count {
      position: absolute;
      bottom: 0;
      right: 0;
      width: var(--sp-4);
      height: var(--sp-4);
      background: var(--shade10);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      p {
        color: var(--shade0);
      }
    }
  }
  .player-position {
    cursor: pointer;
    text {
      font-size: 0.5rem;
    }
  }
`;

// function getRotation(lineup) {
//   return 45 - (Math.atan((lineup.coordinates[1] / lineup.coordinates[0])) * 180 / Math.PI);
// }

function useLineupGroups(lineups, grenadePosition) {
  return useMemo(() => {
    const groups = {};
    const lineupIdToGroupId = {};
    const coordType =
      grenadePosition === "end" ? "coordinates" : "playerCoords";
    for (const lineup of lineups) {
      const c = lineup[coordType];
      const groupId =
        Object.keys(groups).find((gId) => {
          const g = lineups.find((l) => l.id === gId);
          const gc = g[coordType];
          return (
            gc[0] - 5 < c[0] &&
            c[0] < gc[0] + 5 &&
            gc[1] - 5 < c[1] &&
            c[1] < gc[1] + 5
          );
        }) || lineup.id;
      if (!groups[groupId]) groups[groupId] = [];
      groups[groupId].push(lineup.id);
      lineupIdToGroupId[lineup.id] = groupId;
    }
    return { groups, lineupIdToGroupId };
  }, [grenadePosition, lineups]);
}

export function useLineupGroup(selectedLineup, lineups, grenadePosition) {
  const { groups, lineupIdToGroupId } = useLineupGroups(
    lineups,
    grenadePosition,
  );
  return useMemo(() => {
    const selectedLineupGroupId = lineupIdToGroupId[selectedLineup?.id];
    return selectedLineupGroupId
      ? lineups.filter((l) => groups[selectedLineupGroupId].includes(l.id))
      : [];
  }, [groups, lineupIdToGroupId, selectedLineup, lineups]);
}

function GrenadeLineupsMap({
  selectedMap,
  selectedLineup,
  setSelectedLineup,
  grenadePosition,
  lineups,
}) {
  const { groups, lineupIdToGroupId } = useLineupGroups(
    lineups,
    grenadePosition,
  );
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const selectedLineupGroupId = lineupIdToGroupId[selectedLineup?.id];
  const groupLineupsOrigin =
    selectedGroupId || selectedLineupGroupId
      ? lineups.find(
          (l) => l.id === selectedGroupId || l.id === selectedLineupGroupId,
        )
      : null;
  const groupLineups =
    selectedGroupId || selectedLineupGroupId
      ? lineups.filter((l) =>
          groups[selectedGroupId || selectedLineupGroupId].includes(l.id),
        )
      : [];

  const SelectedGrenadeSVG = getGrenadeIcon(
    selectedLineup?.grenade || groupLineupsOrigin?.grenade,
  );

  return (
    <Map>
      <img src={getMapLayoutOffense(selectedMap)} alt="map layout" />
      <svg
        viewBox={`0 0 ${MAP_SIZE} ${MAP_SIZE}`}
        width={`${MAP_SIZE}px`}
        height={`${MAP_SIZE}px`}
        xmlns="http://www.w3.org/2000/svg"
        className="lineups"
      >
        {!groupLineupsOrigin && grenadePosition !== "end"
          ? Object.keys(groups).map((groupId) => {
              const lineup = lineups.find((l) => l.id === groupId);
              return (
                <g
                  className="player-position"
                  key={`${lineup.key}-player-pos`}
                  onClick={() =>
                    groups[groupId].length > 1
                      ? setSelectedGroupId(groupId)
                      : setSelectedLineup(lineup.id)
                  }
                >
                  <circle
                    r="8px" // var(--sp-2) is not working for some reason on chrome
                    cx={`${lineup.playerCoords[0] + 1.125}%`}
                    cy={`${lineup.playerCoords[1] + 1.125}%`}
                    color="var(--shade0)"
                  />
                  <circle
                    r="6px"
                    cx={`${lineup.playerCoords[0] + 2.125}%`}
                    cy={`${lineup.playerCoords[1] + 2.125}%`}
                    color="var(--shade9)"
                    opacity={groups[groupId].length > 1 ? "1" : "0"}
                  />
                  <text
                    className="type-overline"
                    x={`${lineup.playerCoords[0] + 1.8}%`}
                    y={`${lineup.playerCoords[1] + 2.725}%`}
                    color="var(--shade0)"
                    opacity={groups[groupId].length > 1 ? "1" : "0"}
                  >
                    {groups[groupId].length}
                  </text>
                </g>
              );
            })
          : null}
        {groupLineupsOrigin && grenadePosition === "end"
          ? groupLineups.map((gl) => {
              return (
                <g
                  key={`${gl.id}-group`}
                  opacity={
                    selectedLineup && gl.id !== selectedLineup.id ? "0.5" : "1"
                  }
                >
                  <line
                    x1={`${groupLineupsOrigin.coordinates[0]}%`}
                    y1={`${groupLineupsOrigin.coordinates[1]}%`}
                    x2={`${gl.playerCoords[0] + 1.125}%`}
                    y2={`${gl.playerCoords[1] + 1.125}%`}
                    stroke="var(--shade0-50)"
                    strokeWidth="var(--sp-0_5)"
                    strokeDasharray="5,5"
                  />
                  <circle
                    r="8px"
                    cx={`${gl.playerCoords[0] + 1.125}%`}
                    cy={`${gl.playerCoords[1] + 1.125}%`}
                    color="var(--shade0)"
                    onClick={() => {
                      setSelectedGroupId(null);
                      setSelectedLineup(gl);
                    }}
                    className="player-position"
                  />
                </g>
              );
            })
          : null}
        {groupLineupsOrigin && grenadePosition !== "end" ? (
          <g>
            <circle
              r="8px"
              cx={`${groupLineupsOrigin.playerCoords[0] + 1.125}%`}
              cy={`${groupLineupsOrigin.playerCoords[1] + 1.125}%`}
              color="var(--shade0)"
              onClick={() => {
                setSelectedGroupId(null);
                setSelectedLineup(null);
              }}
              className="player-position"
              stroke="var(--shade0-50)"
              strokeWidth="8px"
            />
            {groupLineups.map((gl) => {
              return (
                <line
                  x1={`${gl.coordinates[0]}%`}
                  y1={`${gl.coordinates[1]}%`}
                  x2={`${groupLineupsOrigin.playerCoords[0] + 1.125}%`}
                  y2={`${groupLineupsOrigin.playerCoords[1] + 1.125}%`}
                  stroke="var(--shade0-50)"
                  strokeWidth="var(--sp-0_5)"
                  strokeDasharray="5,5"
                  key={`${gl.id}-group`}
                  opacity={
                    selectedLineup && gl.id !== selectedLineup.id ? "0.5" : "1"
                  }
                />
              );
            })}
          </g>
        ) : null}
      </svg>
      {groupLineupsOrigin && grenadePosition === "end" ? (
        <SelectedGrenadeSVG
          className="selected grenade-svg"
          style={{
            top: `${groupLineupsOrigin.coordinates[1]}%`,
            left: `${groupLineupsOrigin.coordinates[0]}%`,
          }}
          onClick={() => {
            setSelectedLineup(null);
            setSelectedGroupId(null);
          }}
        />
      ) : null}
      {groupLineupsOrigin && grenadePosition !== "end"
        ? groupLineups.map((gl) => {
            return (
              <SelectedGrenadeSVG
                key={`${gl.id}-grenade`}
                className="grenade-svg"
                style={{
                  top: `${gl.coordinates[1]}%`,
                  left: `${gl.coordinates[0]}%`,
                }}
                onClick={() => {
                  setSelectedGroupId(null);
                  setSelectedLineup(gl.id);
                }}
              />
            );
          })
        : null}
      {!groupLineupsOrigin &&
        grenadePosition === "end" &&
        Object.keys(groups).map((groupId) => {
          const lineup = lineups.find((l) => l.id === groupId);
          const GrenadeSVG = getGrenadeIcon(lineup.grenade);

          return (
            <div
              key={`${lineup.id}-grenade-icon`}
              style={{
                top: `${lineup.coordinates[1]}%`,
                left: `${lineup.coordinates[0]}%`,
              }}
              className="grenade-svg"
              onClick={() =>
                groups[groupId].length > 1
                  ? setSelectedGroupId(groupId)
                  : setSelectedLineup(lineup.id)
              }
            >
              <GrenadeSVG />
              {groups[groupId].length > 1 ? (
                <div className="count type-overline">
                  {groups[groupId].length}
                </div>
              ) : null}
            </div>
          );
        })}
    </Map>
  );
}

function TransformedMap({
  selectedMap,
  selectedLineup,
  setSelectedLineup,
  grenadePosition,
  lineups,
}) {
  return (
    <TransformWrapper
      {...{
        defaultScale: 1,
        limitToBounds: true,
        maxScale: 5,
        minScale: 0.5,
        wheel: {
          step: 0.5,
        },
      }}
    >
      {() => (
        <div className="map-container">
          <TransformComponent>
            <GrenadeLineupsMap
              selectedMap={selectedMap}
              selectedLineup={selectedLineup}
              setSelectedLineup={setSelectedLineup}
              grenadePosition={grenadePosition}
              lineups={lineups}
            />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
}

export default TransformedMap;
