import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import type { TagProps } from "clutch/src/Tag/Tag.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import { LINEUP_DIFFICULTIES, MAPS } from "@/game-cs2-marketing/constants.mjs";
import { getGrenadeIcon } from "@/game-cs2-marketing/utils.mjs";
import type { LineupCardProps } from "@/shared-fps/LineupCards.jsx";
import LineupCards from "@/shared-fps/LineupCards.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const DifficultyTagClass = () => css`
  span.difficulty {
    display: block;
    height: var(--sp-1_5);
    width: var(--sp-1_5);
    margin: var(--sp-1_5);
    border-radius: 100%;
    &[data-difficulty="Easy"] {
      background: var(--green);
    }
    &[data-difficulty="Medium"] {
      background: var(--yellow);
    }
    &[data-difficulty="Hard"] {
      background: var(--red);
    }
  }
`;

export function getLineupTags(t, lineup): TagProps[] {
  const difficulty = LINEUP_DIFFICULTIES[lineup.difficulty.toLowerCase()];
  return [
    {
      iconLeft: (
        <span className="difficulty" data-difficulty={lineup.difficulty} />
      ),
      color: "var(--shade1)",
      text: t(...difficulty.t),
      size: "small",
      className: DifficultyTagClass(),
    },
    {
      size: "small",
      text: lineup.action,
      color: "var(--shade1)",
    },
    {
      size: "small",
      text: MAPS[lineup.map]?.name,
      color: "var(--shade1)",
    },
  ];
}

function GrenadeLineupCards({ lineups, compact }) {
  const { t } = useTranslation();
  const { currentPath, searchParams } = useRoute();
  const lineupsProperties: LineupCardProps[] = useMemo(() => {
    return lineups.map((lineup) => {
      const GrenadeSVG = getGrenadeIcon(lineup.grenade);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("lineup", lineup.id);
      newSearchParams.set("lineupMap", lineup.map);
      return {
        id: `${lineup.map}-${lineup.id}`,
        title: lineup.title,
        href: `${currentPath}?${newSearchParams}`,
        imgSrc: `${appURLs.CDN}/blitz/cs2/vidThumbs/${lineup.video.mp4.split(".")[0]}.webp`,
        icon: !compact ? <GrenadeSVG /> : null,
        tags: getLineupTags(t, lineup),
        compact,
      };
    });
  }, [t, currentPath, lineups, compact, searchParams]);

  return <LineupCards lineups={lineupsProperties} compact={compact} />;
}

export default GrenadeLineupCards;
