import router from "@/__main__/router.mjs";
import globals from "@/util/global-whitelist.mjs";

interface ShareOverrides {
  title?: string;
  text?: string;
  url?: string;
}

function shareClickHandler(t, overrides: ShareOverrides = {}) {
  const useNativeShare = Boolean(globals.navigator?.share);
  const { searchParams, currentPath, metadata } = router.route || {};
  const url = currentPath
    ? `${globals.location.origin}${encodeURI(currentPath)}?${searchParams}`
    : globals.location.href;
  if (useNativeShare) {
    globals.navigator.share({
      title: metadata ? t(...metadata.title) : "Blitz",
      text: metadata
        ? t(...metadata.description)
        : "View amazing contents of this link",
      url,
      ...overrides,
    });
  } else {
    globals.navigator.clipboard.writeText(overrides.url ?? url);
  }
}

export default shareClickHandler;
